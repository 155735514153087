@mixin text-link {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.1;

    @include tablet {
        font-size: 16px;
        line-height: 20px;
    }
}

@mixin reset-list() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin reset-button() {
    margin: 0;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background-color: transparent;
    user-select: none;
    font-family: inherit;
    color: inherit;
    letter-spacing: inherit;
    font-size: inherit;
}

@mixin column-count($columnCount, $columnGap) {
    -webkit-column-count: $columnCount;
    -moz-column-count: $columnCount;
    column-count: $columnCount;
    -webkit-column-gap: $columnGap;
    -moz-column-gap: $columnGap;
    column-gap: $columnGap;
}

// just an example
@mixin button-green() {
    background-color: lightgreen;

    .can-hover &:hover {
        background-color: green;
    }
}

// just an example
@mixin link-green() {
    color: green;

    svg {
        fill: currentColor;
    }

    .can-hover &:hover {
        color: darkgreen;

        svg {
            fill: currentColor;
        }
    }
}

@mixin link-reset() {
    text-decoration: none;
    color: inherit;
}

@mixin link() {
    @include link-reset();
    @include text-link();
    @include link-green();

    @include hover {
        cursor: pointer;
    }
}

@mixin object-fit-image-parent($fit: cover) {
    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: $fit;

        @content;
    }
}

@mixin container() {
    margin-left: auto;
    margin-right: auto;
}

@mixin hover() {
    .can-hover &:hover {
        @content;
    }
}

@mixin keyboard-focus {
    [data-whatintent='keyboard'] & {
        outline: $outline-default;
    }
}

@mixin visually-hidden() {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
}

// Border Radius
@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

// Spectrum Border Radius
@mixin spectrum-radius() {
    border-radius: 20px 20px 0 20px;
    -webkit-border-radius: 20px 20px 0 20px;
    -moz-border-radius: 20px 20px 0 20px;
}

// Transition Property
@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

// Grey Border
@mixin grey-border() {
    border: 1px solid #e0e0e0;
}

// Block Shadow
@mixin block-shadow() {
    -webkit-box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.04);
    -moz-box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.04);
    box-shadow: 10px 10px 15px 0px rgba(0,0,0,0.04);
}

// Clearfix
@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        display: block;
        clear: both;
        height: 1px;
        margin-top: -1px;
        visibility: hidden;
    }

    & {
        *zoom: 1;
    }
}

// Browser Prefixes
@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    transform-origin: $origin;
}

@mixin shimmerAnimation() {
    animation: shimmer 2s linear infinite normal both;

    @keyframes shimmer {
        0% {
            opacity: 0.3;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.3;
        }
    }

}

@mixin gradientLoadAnimation() {
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), #A1A1A1, rgba(255, 255, 255, 0));
        animation: loading 2s forwards infinite;
    }

    @keyframes loading {
        from {
            transform: translateX(-100%);
        }

        to {
            transform: translateX(300%);
        }
    }
}

@mixin fadeIn($time: 0.5) {
    animation: fadein #{$time}s;

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}

@mixin reset-fieldset() {
    margin: 0;
    padding: 0;
    border: none;
}

@mixin hoverTopTransition() {
    transition: transform .6s cubic-bezier(.25,.46,.45,.94);

    .can-hover &:hover {
        transform: translateY(-5px);
    }
}

