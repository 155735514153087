.make-donation {
    $this: &;

    padding: 20px 0;

    @include tablet {
        padding: 40px 0;
    }

    &__title {
        color: $color-violet-blue;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 1.3;

        @include tablet {
            font-size: 32px;
        }

        span {
            color: $color-purple-pink;
        }
    }

    &__copy {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.5;
    }

    &__form {
        margin-top: 15px;

        @include tablet {
            margin-top: 30px;
        }
    }

    &__wrap {
        margin-top: 15px;

        @include tablet {
            margin-top: 0;
        }
    }

    &__ways-btn {
        display: block;
    }

    &__col {
        margin-top: 15px;

        @include tablet {
            margin-top: 0;
        }
    }

    &__signpost {
        width: 100%;

        & + & {
            margin-top: 15px;
        }
    }

    &--impact {
        #{$this}__title {
            width: 290px;
            margin: 0 auto;
            font-size: 22px;
            text-align: center;

            @include tablet {
                width: 630px;
                font-size: 46px;
                line-height: 1.17;
            }
        }

        #{$this}__subtitle {
            color: $color-violet-blue;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: -0.5px;
            line-height: 1.27;
            text-align: center;

            @include tablet {
                font-size: 32px;
                font-weight: 600;
                text-align: left;
            }
        }

        #{$this}__signposts {
            margin-top: 30px;
        }

        #{$this}__signpost-wrap {
            padding: 7px;

            @include tablet {
                padding: 15px;
            }
        }

        #{$this}__signpost {
            height: 100%;

            @include tablet {
                margin-top: 0;
            }
        }

        #{$this}__wrap {
            margin-top: 0;
            padding-top: 40px;
            padding-left: 0;

            @include tablet {
                padding-top: 60px;
            }
        }

        #{$this}__row {
            position: relative;
            margin-top: 30px;
            background-color: $color-violet-blue;
            border-radius: 20px 20px 0 20px;

            @include tablet {
                margin-top: 60px;
            }
        }

        #{$this}__tip {
            margin: 60px auto 0 auto;

            @include tablet {
                position: absolute;
                right: -30px;
                bottom: -40px;
                margin-top: 0;
            }

            @include desktop {
                right: -40px;
            }
        }

        #{$this}__image {
            position: relative;
            overflow: hidden;
            min-height: 210px;
            border-bottom-left-radius: 20px;


            @include tablet {
                height: 100%;
                border-top-right-radius: 20px;
                border-bottom-left-radius: 0;
            }

            &::after {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                display: block;
                transform: rotate(180deg);
                width: 100%;
                height: 30px;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 105% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 32' preserveAspectRatio='none'%3E%3Cpath fill='%234203BF' d='M0,0C29.61,12.78,82.63,30.18,151,30c67.31-.18,119.53-17.28,149-30V32H0Z' /%3E%3Cpath d='M188,55' /%3E%3Cpath d='M164,56' /%3E%3C/svg%3E%0A");

                @include tablet {
                    top: 0;
                    bottom: 0;
                    left: -1px;
                    transform: none;
                    width: 90px;
                    height: 100%;
                    background-size: 100% 105%;
                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 555' preserveAspectRatio='none' style='enable-background:new 0 0 100 555;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234203BF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-1,555.5c33.17,0,68.83,0,102,0c-20.24-24.63-100-108-100-278c0-118.01,71.53-245,98.5-277 C66.33,0.33,32.17,0.67-1,0.5C-1,185.5-1,370.5-1,555.5z'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &--hero {
        padding: 0;

        #{$this}__form {
            position: relative;
            margin-top: 0;
            background-color: $color-violet-blue;
        }

        #{$this}__image-wrap {
            position: relative;
        }

        #{$this}__image {
            position: relative;
            z-index: 0;
            height: 100%;
            min-height: 215px;

            @include desktop {
                min-height: 555px;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: -1px;
                left: 0;
                display: block;
                width: 100%;
                height: 30px;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 105% 100%;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300 32' preserveAspectRatio='none'%3E%3Cpath fill='%234203BF' d='M0,0C29.61,12.78,82.63,30.18,151,30c67.31-.18,119.53-17.28,149-30V32H0Z' /%3E%3Cpath d='M188,55' /%3E%3Cpath d='M164,56' /%3E%3C/svg%3E%0A");

                @include tablet {
                    top: 0;
                    left: -1px;
                    width: 90px;
                    height: 100%;
                    background-size: 100% 105%;
                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 100 555' preserveAspectRatio='none' style='enable-background:new 0 0 100 555;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%234203BF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M-1,555.5c33.17,0,68.83,0,102,0c-20.24-24.63-100-108-100-278c0-118.01,71.53-245,98.5-277 C66.33,0.33,32.17,0.67-1,0.5C-1,185.5-1,370.5-1,555.5z'/%3E%3C/svg%3E%0A");
                }
            }
        }

        #{$this}__spectrum {
            left: -5%;
            width: 50%;

            @include tablet {
                left: -20%;
                z-index: 1;
                width: 250px;
            }

            @include desktop {
                left: -8%;
            }
        }

        #{$this}__curve {
            display: none;

            @include tablet {
                position: absolute;
                bottom: -1px;
                left: 0;
                display: block;
                width: 100%;
                height: 60px;
                color: white;
            }
        }

        #{$this}__scroll-down {
            display: none;

            @include tablet {
                position: absolute;
                top: 100%;
                left: 40px;
                display: block;
                width: 13px;
                height: 20px;
                color: $color-purple-pink;
            }

            @include desktop-wide {
                left: 60px;
            }
        }

        #{$this}__tip-wrap {
            display: none;

            @include tablet {
                position: relative;
                display: block;
                padding: 50px;
            }
        }

        #{$this}__tip {
            @include tablet {
                position: absolute;
                right: 60px;
                bottom: 0;
            }
        }

        #{$this}__signposts {
            padding: 30px $mobile-small-padding 40px;
            background-color: $color-pale-grey;

            @include tablet {
                padding: 0 $mobile-medium-padding 40px;
                background-color: white;
            }
        }

        #{$this}__signpost-title {
            color: $color-violet-blue;
        }

        #{$this}__signpost-list {
            @include reset-list;

            margin-top: 20px;

            @include tablet {
                margin-top: 30px;
            }
        }

        #{$this}__signpost-item {
            & + #{$this}__signpost-item {
                margin-top: 20px;

                @include tablet {
                    margin-top: 0;
                }
            }
        }

        #{$this}__signpost-item-inner {
            height: 100%;
        }
    }
}
