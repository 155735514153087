.make-donation-signpost {
    $this: &;

    @include reset-button();

    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    padding: 20px;
    background: white;
    border: 1px solid $color-white-three;
    border-radius: 20px 20px 0 20px;
    color: $color-violet-blue;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 1.27;
    text-decoration: none;
    cursor: pointer;

    @include tablet {
        min-height: 100px;
        padding: 30px;
        font-size: 22px;
    }

    &__wrap {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__circle {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin-left: 5px;
        padding: 8px 0;
        border: 1px solid $color-lavender;
        border-radius: 50%;
        color: $color-lavender;
        text-align: center;
    }

    &__title {
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -0.5px;
        line-height: 1.3;

        @include tablet {
            font-size: 32px;
            font-weight: 600;
        }
    }

    &__text {
        margin-top: 10px;
        color: $color-greyish-brown;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;

        @include tablet {
            font-size: 14px;
        }
    }

    &--hero {
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px;
        text-align: left;

        @include tablet {
            height: 100%;
            background-color: $color-pale-grey;
        }

        #{$this}__wrap {
            justify-content: space-between;
        }

        #{$this}__circle {
            display: none;

            @include tablet {
                display: block;
                flex-shrink: 0;
                margin-left: 10px;
            }
        }
    }
}
