.donation-form-radio {
    &__label {
        @include transition(background-color, 0.3s, ease);

        display: inline-block;
        vertical-align: top;
        width: 100%;
        min-width: 75px;
        padding: 8px 10px;
        background-color: $color-bluish-purple;
        border: 2px solid transparent;
        border-radius: 25px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;

        @include tablet {
            padding: 14px 10px;
            font-size: 16px;
        }

        &:hover {
            background-color: rgba($color-white, 0.15);
        }

        &.is-invalid {
            background-color: $color-rosy-pink;
        }
    }

    &__native:checked ~ &__label {
        border-color: $color-white;

        &:hover {
            background-color: $color-bluish-purple;

        }
    }
}
