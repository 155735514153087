.donation-form {
    $this: &;

    height: 100%;
    background-color: $color-violet-blue;
    border-radius: 20px 20px 0 20px;
    color: white;

    &__title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.5px;
        line-height: 1.2;

        @include tablet {
            font-size: 26px;
            line-height: 1.4;
        }
    }

    &__subtitle {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;

        @include tablet {
            margin-right: 10px;
            font-size: 16px;
        }
    }

    &__nav-list {
        @include reset-list();

        display: flex;
        align-items: flex-end;
        margin-top: 15px;
    }

    &__nav-li {
        flex: 1;
    }

    &__nav-link {
        opacity: 0.75;
        display: block;
        padding: 15px 5px;
        border-bottom: 2px solid $color-blue-violet;
        color: inherit;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        text-decoration: none;

        @include tablet {
            font-size: 16px;
        }

        &:first-child {
            border-top-left-radius: 1px;
            border-bottom-left-radius: 1px;
        }

        &:last-child {
            border-top-left-radius: 1px;
            border-bottom-left-radius: 1px;
        }

        &.is-active {
            opacity: 1;
            border-bottom: 2px solid white;
            border-radius: 1px;
        }
    }

    &__content {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__defined {
        margin-top: 20px;

        @include tablet {
            display: flex;
            align-items: center;
            margin-top: 30px;
        }
    }

    &__defined-list {
        @include reset-list();

        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-left: auto;

        @include tablet {
            width: 75%;
            margin-top: 0;
        }
    }

    &__defined-li {
        flex: 1;
        max-width: 50%;

        & + & {
            margin-left: 10px;

            @include tablet {
                margin-left: 22px;
            }
        }
    }

    &__holder {
        @include tablet {
            display:flex;
            width:100%;
        }
    }

    &__other {
        margin-top: 20px;

        @include tablet {
            display: flex;
            align-items: center;
            margin-top: 30px;
        }
    }

    &__other-wrap {
        @include tablet {
            display: flex;
            width: 75%;
            margin-left: auto;
        }
    }

    &__other-field {
        margin-top: 10px;

        @include tablet {
            width: 50%;
            margin: 0 15px 0 auto;
        }
    }

    &__submit {
        width: 100%;
        min-height: 45px;
        margin-top: 20px;
        background-color: $color-purple-pink;
        font-size: 14px;

        @include tablet {
            width: 50%;
            min-height: 50px;
            margin-top: 0;
            font-size: 16px;
        }
    }

    &__error {
        &[aria-hidden="false"] {
            display: flex;
            margin-top: 15px;
            padding: 10px $padding-side-mobile;
            background-color: $color-pale-pink;
            border-radius: 10px;
            color: $color-falu-red;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;

            @include tablet {
                margin-top: 30px;
                padding: 18px $padding-side-tablet;
            }
        }
    }

    &--standard {
        padding: 20px;

        @include tablet {
            padding: 30px;
        }

        #{$this}__subtitle {
            @include tablet {
                width: 25%;
            }
        }
    }

    &--impact {
        padding: 20px 20px 30px;
        border-radius: 20px 20px 0 0;
        color: white;

        @include tablet {
            padding: 40px 30px;
            border-radius: 20px 0 0 20px;
        }

        #{$this}__title {
            @include tablet {
                font-size: 32px;
            }
        }

        #{$this}__subtitle {
            align-self: flex-start;
            margin: 0;
        }

        #{$this}__defined {
            flex-direction: column;
        }

        #{$this}__defined-list,
        #{$this}__other-wrap {
            width: 100%;
            margin-top: 10px;
        }

        #{$this}__defined-li + #{$this}__defined-li {
            margin-left: 10px;

            @include tablet {
                margin-left: 15px;
            }
        }

        #{$this}__nav-list {
            margin-top: 25px;
        }

        #{$this}__other {
            flex-direction: column;
        }
    }

    &--hero {
        padding: 15px $padding-side-mobile 30px;
        border-radius: 0;

        @include tablet {
            padding: 40px 90px 100px 40px;
        }

        @include desktop {
            padding: 80px 60px 100px 50px;
        }

        @include desktop-wide {
            padding: 80px 120px 100px 90px;
        }

        #{$this}__form {
            margin-top: 0;
        }

        #{$this}__defined-list,
        #{$this}__other-wrap {
            @include tablet {
                width: 100%;
            }
        }

        #{$this}__title {
            @include desktop {
                font-size: 46px;
                font-weight: 700;
            }
        }

        #{$this}__nav-link {
            font-size: 12px;
            font-weight: 400;

            @include desktop {
                font-size: 14px;
            }

            &.is-active {
                font-weight: 700;
            }
        }

        #{$this}__subtitle {
            @include tablet {
                width: 25%;
            }
        }

        #{$this}__defined-li + #{$this}__defined-li {
            margin-left: 15px;
        }
    }
}
