.spinner {
    $this: &;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    font-size: 0;
    text-indent: -9999em;

    &::after {
        content: "";
        overflow: hidden;
        display: block;
        transform: translateZ(0) ;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-top: 10px solid rgba($color-white, 0.2);
        border-right: 10px solid rgba($color-white, 0.2);
        border-bottom: 10px solid rgba($color-white, 0.2);
        border-left: 10px solid $color-white;
        border-radius: 50%;
        font-size: 10px;
        animation: load8 1.1s infinite linear;
    }

    &--small {
        &::after {
            width: 30px;
            height: 30px;
            border-width: 3px;
        }
    }

    &--purple {
        &::after {
            border-top-color: rgba($color-medium-purple, 0.4);
            border-right-color: rgba($color-medium-purple, 0.4);
            border-bottom-color: rgba($color-medium-purple, 0.4);
            border-left-color: rgba($color-medium-purple, 0.8);
        }
    }
}

.spinner-container {
    $this: &;

    position: relative;

    &__spinner {
        z-index: $layer-element-spinner;
        display: none;
    }

    &.is-spinner-shown {
        #{$this}__spinner {
            display: block;
        }
    }


}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
