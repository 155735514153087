.donation-form-field {
    $this: &;

    position: relative;
    color: $color-greyish-brown;

    &__native {
        @include transition(background-color, 0.3s, ease);

        width: 100%;
        height: 35px;
        padding: 0 30px;
        border: none;
        border-radius: 25px;

        @include tablet {
            height: 50px;
        }
    }

    &__input {
        width: 100%;
        height: 35px;
        padding: 0 25px;
        border: none;
        border-radius: 25px;
    }

    &__label {
        @include transition(color, 0.3s, ease);

        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 1.5;
    }

    &__currency {
        vertical-align: middle;
        padding: 5px 0;
    }
}
