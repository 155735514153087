$color-dark-slate-blue: #213A64;
$color-slate-blue: #23358F;
$color-dusk: #43587C;
$color-light-blue-grey: #DFD7F0;
$color-violet-blue: #4203BF;
$color-blue-violet: #6600F0;
$color-bluish-purple: #7439EA;
$color-darkish-purple: #6F2488;
$color-barney: #8913B0;
$color-medium-purple: #844499;
$color-barney-two: #C218AD;
$color-pale-pink: #F2DEDF;
$color-purple-pink: #D31ABC;
$color-purple-pink-two: #B332DD;
$color-purple-pink-three: #BF18AB;
$color-lavender: #D0C0EF;
$color-faded-red: #DB5354;
$color-rust-red: #A22602;
$color-falu-red: #972200;
$color-rosy-pink: #EF6263;
$color-butterscotch: #FFB246;
$color-macaroni-and-cheese: #E7982A;
$color-fun-green: #027D48;
$color-teal: #02807C ;
$color-aqua-blue: #00C3DB;
$color-aquamarine: #00D1CE;
$color-neon-blue: #0BDFFA;
$color-black: #000000;
$color-greyish-brown: #3F3F3F;
$color-warm-grey: #757575;
$color-warm-grey-two: #656565;
$color-pinkish-grey: #BBBBBB;
$color-white: #FFFFFF;
$color-white-two: #ECECEC;
$color-white-three: #E0E0E0;
$color-pale-grey: #F0EDF6;
$color-lavender: #D0C0EF;
$color-pale-lavender: #FBFAFF;
$color-solitude: #E8EBEF;
$color-silver: #CCCCCC;
$color-dark-grey: #363636;
$color-myrtle: #081B00;
$color-hint-green: #DFF0D8;
$color-corn-silk: #FDF8E3;
$color-raw-umber: #6B4106;
$color-dark-grey-two: #7C7C7C;
$color-turquoise: #32BCAD;

/* TODO:refactor */
$primary-color: #4203BF;
$secondary-color: #D31ABC;
$tertiary-color: #7439EA;
$purple: #8913B0;
$orange: #EF6263;
$green: #01ADAB;
$grey: #F0EDF6;
$dark-blue: #213A64;
$body-font-color: #535353;
$color-disabled-background: rgb(235, 235, 228);
$color-disabled-border: rgb(169, 169, 169);
$color-disabled-text: rgb(128, 128, 128);
$color-focus-outline: rgba(59, 153, 252, 0.7);
$black-75: #3F3F3F;
$black-50: #757575;
$black-25: #BBBBBB;
$black-10: #E0E0E0;
$black-5: #ECECEC;
$washed-chalk: #F0EDF6;
$deep-yellow: #FFB246;
$warm-red: #EF6263;
$bright-blue: #0BDFFA;
