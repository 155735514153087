// $defaultTransitionDuration is loaded via sass-vars-loader
$transition-default: (($defaultTransitionDuration - 1) + 1ms) ease-out;
$outline-default: 2px solid $color-focus-outline;

$default-mobile-side-padding: 15px;
$default-tablet-side-padding: 40px;
$default-desktop-side-padding: 50px;

$stacked-content-margin-mobile: 25px;
$stacked-content-margin-tablet: 40px;
$stacked-content-margin-desktop: 50px;

// $containerMaxWidth is loaded via sass-vars-loader
$container-max-width: ($containerMaxWidth - 1) + 1px;
$narrow-container-max-width: 964px;
$container-mid-max-width: 800px;

$layer-selector: 50;
$layer-menu: 200;
$layer-element-spinner: 300;
$layer-overlay: 300;
$layer-modal: 500;
$layer-notification: 600;
$layer-page-spinner: 700;
$layer-cookie-banner: 800;

$desktop-large-padding:80px;
$desktop-medium-padding:60px;
$desktop-small-padding:30px;
$desktop-xsmall-padding:15px;

$tablet-large-padding:80px;
$tablet-medium-padding: 60px;
$tablet-small-padding: 30px;
$tablet-xsmall-padding: 30px;

$mobile-large-padding: 40px;
$mobile-medium-padding: 30px;
$mobile-small-padding: 15px;
$mobile-xsmall-padding: 10px;

$container-max-width: 1260px;

$padding-side-mobile: 15px;
$padding-side-tablet: 30px;
